<template>
	<Header />

      <div class="container-fluid relative">
            <!-- <Row :key="row.id" v-for="row in data.rows" :row="row" :headers="data.headers" /> -->

            <div class="utilities flex flex-wrap">
                  <div v-for="util in sp.rows" :key="util.id" class="util">
                        <div class="scores flex gap-10 w-full justify-center">
                              <div class="">
                                    <div class="score sp">{{util.sp_score}}</div>
                                    <div class="label">Principles</div>
                              </div>
                              <div class="">
                                    <div class="score effort">{{util.effort_score}}</div>
                                    <div class="label">Effort</div>
                              </div>            
                        </div>
                        <h3 class="title">{{util.utility}}</h3>

                  </div>
            </div>
      </div>

</template>

<script>
import Header from '@/components/Header';
import { mapState } from 'vuex';

export default {
	name: 'Utilities',
	components: {
		Header,
	},
	computed: {
		...mapState(['sp']),
	},
	props: {
		msg: String
	},
	data() {
		return {

		}
	},
	mounted() {
		//this.$store.commit('setView', 'effort');
	}
}


</script>


<style lang="scss" scoped>
	
      .util {
            background-color: #132430;
            border-radius: 15px;
            padding: 30px;
            margin: 0 30px 30px 0;
            width: calc(25% - 30px);
            height: 300px;
            text-align: center;
            color: white;
      }

      .scores {
            .score {
                  border: 5px solid #2081c6;
                  color: #2081c6;
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  font-size: 35px;
                  font-weight: bold;
                  line-height: 90px;

                  &.sp {
                        border-color: #10ADB8;
                        color: #10ADB8;
                  }
            }

            .label {
                  font-size: 13px;
                  margin-top: 10px;
                  color: #999;
            }
      }

      .title {
            width: 100%;
            border-top: 1px solid #666;
            padding-top: 20px;
            margin-top: 20px;
            font-size: 17px;
      }

</style>
