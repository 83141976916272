<template>
	<tr>	
		<Cell col="utility" inputFormat="text" :cell="row.utility" :rowNum="row.rowNum" :total_score="row.total_score" :sp_score="row.sp_score" :effort_score="row.effort_score" />
		<Cell v-for="(cell,index) in row.cols" :key="cell.index" inputFormat="number" :col="index" :cell="cell" :rowNum="row.rowNum" />
	</tr>
</template>

<script>
	import Cell from '@/components/Cell.vue';
	import { mapState } from 'vuex';

	export default {
		name: "Row",
		props: ["row","headers"],
		components: {
			Cell
		},
		data() {
			return {
				rowData: this.row
			}
		},
		computed: {
			...mapState(['tiering'])
		},
		methods:{
			
		}
	}
	
</script>

<style lang="scss" scoped>
	
	

</style>