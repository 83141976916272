import { createStore } from 'vuex';
import axios from 'axios';
const { GoogleSpreadsheet } = require('google-spreadsheet');

export default createStore({
	//strict: process.env.NODE_ENV !== "production",
      
	state() {
		return {
			doc: {},
			sheet: {},
			docID: '1bOjT9aPCDMBM2qpQd6W1CPuBB207EmBPXJc2GZ4k-IQ',
			sheetID: 97220643,
			creds: require('@/client_secret.json'),
			sp: {},
			effort: {},
                  objectives: {},
			general: {},
                  reportData: {datasets:[]},
			spUnfiltered: {},
			effortUnfiltered: {},
			filter: null,
			sort: null,
                  scoring: null,
			view: 'sp',
			lastRowNum: 0,
			newAdded: false,
			metaData: [],
			title: '',
			siteloading: true,
			sheetloading: false
		}
	},
	mutations: {
		filterByMarketSector(state, data) {
			state.filter = data;
				
			if(state.filter) {
				console.log(state.filter)
				let newArr = state[state.view + 'Unfiltered'].filter(function(value) {
					return value.cols['Market Sector'] == state.filter;
				});
				state[state.view].rows = newArr;
			}
		},
		resetFilter(state) {
			state.filter = null;
			state.sort = null;
			state.sp.rows = state.spUnfiltered;
			state.effort.rows = state.effortUnfiltered;
                  console.log(state.sp.rows)
			// state.sp.rows.sort(function(a,b) {
			// 	return b['sp_score'] - a['sp_score'];
			// });
			// state.effort.rows.sort(function(a,b) {
			// 	return b['effort_score'] - a['effort_score'];
			// });

			console.log('reset filter');
		},
		setView(state, data) {
			if(data) {
				if(data != state.view) {
					//reset
					this.commit("resetFilter");
				}
				state.view = data;
				console.log('set view');
			}
			
		},
		sortByHeader(state, data) {
			state.sort = data.header;
			console.log('sort by header');
			
			if(!data.invert) {
				//sort normal

				switch(data.header.toLowerCase()) {
					case 'account' :
						console.log('account');
						state[state.view].rows.sort((a, b) => {
							if(a.cols[data.header] > b.cols[data.header]) return -1;
							if(a.cols[data.header] < b.cols[data.header]) return 1;
							return 0;
						});
					break;
					case 'am current effort' : {
						console.log('am current');
						let score = {
							"": 0,
							low: 1,
							medium: 2,
							high: 3,
						};
						state[state.view].rows.sort((a, b) => {
							console.log(score[a.cols[data.header].toLowerCase()])
							score[a.cols[data.header].toLowerCase()] - score[b.cols[data.header].toLowerCase()];
						});
						}
					break;
					default :
						state[state.view].rows.sort((a, b) => {
							if(a.cols[data.header] < b.cols[data.header]) return -1;
							if(a.cols[data.header] > b.cols[data.header]) return 1;
							return 0;
						});
				}
				
			} else {
				//invert sort
				state[state.view].rows.sort((a, b) => {
					if(data.header.toLowerCase() == 'account') {
						if(a.account > b.account) return -1;
						if(a.account < b.account) return 1;
					} else {
						if(a.cols[data.header] > b.cols[data.header]) return -1;
						if(a.cols[data.header] < b.cols[data.header]) return 1;
					}
					return 0;
				});
			}	
		}
	},
	actions: {
		async loadByCells({commit, state}) {
			const doc = new GoogleSpreadsheet(state.docID);
			await doc.useServiceAccountAuth(state.creds);
			await doc.loadInfo(); 
			const cellsSheet = doc.sheetsById[state.sheetID];			
			console.log('load by cells');
		},
		async loadSheet({commit, state}) {
			console.log('loadSheet');
			state.sheetloading = true;
			//Get total sheet using framework
			state.doc = new GoogleSpreadsheet(state.docID);
			await state.doc.useServiceAccountAuth(state.creds);
			await state.doc.loadInfo(); 
			
			state.originalSheet = state.doc.sheetsById[state.sheetID];			
			await state.originalSheet.loadHeaderRow(3);
			state.title = state.originalSheet.title;
			state.originalRows = await state.originalSheet.getRows({
				offset: 1
			});

			state.sp = {
				headers: [],
				rows: []
			};
			state.objectives = {
				headers: [],
				rows: []
			};
			state.effort = {
				headers: [],
				rows: []
			};
			
			//go through and split up sp and effort headers data, whilst removing 'tiers'
			let spView = 'sp';
			state.originalSheet.headerValues.forEach((header, index) => {
				const _header = header.toLowerCase();

				if(!_header.includes('-') && _header.length > 0) {
					if(_header == 'sp_score') spView = 'effort';
                              if(_header == 'effort_score') spView = 'objectives';
                              //if(_header == 'general info end') spView = 'effort';

					if(!/tiering|score|utility|total|band|account|info end/.test(_header)) {
						switch(spView) {
							case 'sp' :
								state.sp.headers.push(header);
							break;
							case 'effort' :
								state.effort.headers.push(header);
							break;
                                          case 'objectives' :
								state.objectives.headers.push(header);
							break;
						}
					}
				}
			});
			
			// now go through all rows, and build up row data from new headers, whilst adding in tiers and row sheet numbers
			state.scoring = {
                        sp: {
                              low: 0,
                              high: 0
                        },
                        effort: {
                              low: 0,
                              high: 0
                        },
                        total: {
                              low: 0,
                              high: 0
                        }
                  } 

                  state.originalRows.forEach((row, index) => {
				if(row['Utility'] == "") return;
				
				let newRow = {
					cols:{},
					rowNum:row._rowNumber,
					utility: row['Utility']
				}

				//sp
				state.sp.headers.forEach(header => {
					newRow.cols[header] = row[header];
				});
				newRow.sp_score = Number(row['sp_score']).toFixed();
				newRow.effort_score = Number(row['effort_score']).toFixed();
				newRow.total_score = Number(row['total_score'])
                        state.sp.rows.push(newRow);
			
				//effort
				newRow = {cols:{}, rowNum:row._rowNumber, utility: row['Utility']}
				state.effort.headers.forEach(header => {
					newRow.cols[header] = row[header];
				});
				newRow.sp_score = Number(row['sp_score']).toFixed();
				newRow.effort_score = Number(row['effort_score']);
				newRow.total_score = Number(row['total_score'])
                        state.effort.rows.push(newRow);

                        var x = Math.floor(Math.random() * 256);
                        var y = Math.floor(Math.random() * 256);
                        var z = Math.floor(Math.random() * 256);
                        var RGBColor = "rgb(" + x + "," + y + "," + z + ")"; 

                        state.reportData.datasets.push({
                              label: row["Utility"],
                              //title: row['Utility'],
                              backgroundColor: RGBColor,
                              data: [{
                                    x: Number(row['sp_score']),
                                    y: Number(row['effort_score']),
                                    r: (Number(row['sp_score']) / Number(row['effort_score']))*10
                              }],
                        })

                        newRow = {cols:{}, rowNum:row._rowNumber, utility: row['Utility']}
                        state.objectives.headers.forEach(header => {
					if(!row[header]) row[header] = 0; 
                              newRow.cols[header] = row[header];
				});
                        newRow.sp_score = Number(row['sp_score']).toFixed();
				newRow.effort_score = Number(row['effort_score']);
				newRow.total_score = Number(row['total_score'])
                        state.objectives.rows.push(newRow);

                        //work out score bandings
                        if(newRow.sp_score > state.scoring.sp.high) state.scoring.sp.high = newRow.sp_score;
                        if(newRow.effort_score > state.scoring.effort.high) state.scoring.effort.high = newRow.effort_score;
                        if(newRow.total_score > state.scoring.total.high) state.scoring.total.high = newRow.total_score;

                  });

                  //state.reportData.labels = "NFT Utilities";


			//sort rows by tiering
			state.sp.rows.sort(function(a,b) {
				return b.sp_score - a.sp_score;
			});
			state.effort.rows.sort(function(a,b) {
				return b.effort_score - a.effort_score;
			});
                  state.objectives.rows.sort(function(a,b) {
				return b.total_score - a.total_score;
			});
			
			state.spUnfiltered = [...state.sp.rows];
			state.effortUnfiltered = [...state.effort.rows];
			state.lastRowNum = state.originalRows[state.originalRows.length-1]._rowNumber;
			state.siteloading = false;
			state.sheetloading = false;
		},
		async loadMeta({commit, state}) {
			console.log('load meta');
			const doc = new GoogleSpreadsheet(state.docID);
			await doc.useServiceAccountAuth(state.creds);
			await doc.loadInfo(); 

			const meta = doc.sheetsById[118007877];
			await meta.loadHeaderRow(3);
			const metaRows = await meta.getRows();
			
			//extract out columns into data arrays
			const metaData = {};
			metaRows[0]._sheet.headerValues.forEach((category, index) => {
				//don't want first column
				if(index > 0 && !category.includes('-') && category != '') {
					const headerValues = {desc:'', values:[]};
					metaRows.forEach((row, index) => {
						if(index == 0) headerValues.desc = row[category];
						if(index > 0 && row[category] !== "" && row[category]) {
							headerValues.values.push(row[category]);
						}
					});
					metaData[category] = headerValues;
				}
			})

			//state.metaRows = metaRows;
			state.metaData = metaData;
		},
		saveSheet({commit, state}) {
			state.sheet.save();
		}
		
	},
	getters: {
		getSectors: (state) => {
			
		},
		getTop3: (state) => {
			
		},
		getScores: (state) => {
			

			
		}
		
	},
});
