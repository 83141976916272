<template>
	<Header />
	
      <div class="container-fluid table-container relative">

            <Bubble
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            />

      </div>

</template>

<script>
import Header from '@/components/Header';
import { mapState } from 'vuex';
import { Bubble } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, PointElement, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'Report',
  components: { Header, Bubble },
  props: {
    chartId: {
      type: String,
      default: 'bubble'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
        ...mapState(['reportData']),
        chartData() {
            return this.reportData;
        }
  },
  data() {
    return {
      chartOptions: {
            responsive: true,
            scales: {
                  x: {
                      title: {
                        display: true,
                        text: "Success pinciples score"
                      },
                  },
                  y: {
                      title: {
                        display: true,
                        text: "Effort level score"
                      },
                  }
            }
      }
    }
  }
}


</script>


<style lang="scss" scoped>
	
      
</style>
